<div class="buttons">
    <ng-content></ng-content>
    <button mat-stroked-button class="action-button like-button" [disabled]="(liking$ | async) === media.id || userOwnsMedia" [matTooltip]="'Like' | trans" [class.active]="userLibrary.has(media)" (click)="toggleLike(media)">
        <mat-icon svgIcon="favorite-border"></mat-icon>
        {{media.likes_count || ('Like' | trans)}}
    </button>
    <button mat-stroked-button class="action-button repost-button" [disabled]="(reposting$ | async) === media.id || userOwnsMedia" [matTooltip]="'Repost' | trans" *ngIf="settings.get('player.enable_repost')" (click)="toggleRepost(media)">
        <mat-icon svgIcon="repeat"></mat-icon>
        {{media.reposts_count || ('Repost' | trans)}}
    </button>
    <button mat-stroked-button class="action-button share-button" (click)="openShareModal()">
        <mat-icon svgIcon="share"></mat-icon>
        <span trans>Share</span>
    </button>
    <button mat-stroked-button class="action-button more-button" (click)="showContextMenu(media, $event)">
        <mat-icon svgIcon="more-horiz"></mat-icon>
        <span trans>More</span>
    </button>
</div>
<div class="info" *ngIf="media.plays">
    <span>
        <mat-icon svgIcon="play-arrow"></mat-icon>
        {{media.plays | number}}
    </span>
</div>
