<ng-container *ngIf="pagination$ | async as pagination">
    <div class="followed-users" *ngIf="pagination.data.length">
        <div class="follower-row" *ngFor="let user of pagination.data">
            <media-image [media]="user"></media-image>
            <div class="meta">
                <a class="name hover-underline" [routerLink]="urls.user(user)">{{user.display_name}}</a>
                <div class="followers">{{user.followers_count}} <span trans>Followers</span></div>
            </div>
            <button mat-stroked-button class="follow-button" (click)="profile.follow(user, cd)" *ngIf="!profile.currentUserIsFollowing(user) && ! isCurrentUser(user)" trans>Follow</button>
            <button mat-stroked-button class="follow-button" (click)="profile.unfollow(user, cd)" *ngIf="profile.currentUserIsFollowing(user) && ! isCurrentUser(user)" trans>Unfollow</button>
        </div>
    </div>
    <no-results-message *ngIf="!pagination.data.length" svgIcon="bookmark">
        <span primary-text><ng-content></ng-content></span>
    </no-results-message>
</ng-container>
<loading-indicator [isVisible]="loading$ | async" class="inline"></loading-indicator>
