<button class="no-style panel-header" (click)="closePanel(); $event.stopPropagation()">
    <mat-icon svgIcon="arrow-back"></mat-icon>
    <span trans>Back</span>
</button>
<div class="panel-body">
    <div class="title" trans>Select</div>

    <div class="playlists">
        <button type="button" class="new-playlist context-menu-item" (click)="openNewPlaylistModal()">
            <mat-icon svgIcon="playlist-add"></mat-icon>
            <span trans>New playlist</span>
        </button>
        <button type="button" class="playlist context-menu-item" *ngFor="let playlist of playlists" (click)="addTracks(playlist)">
            <mat-icon svgIcon="music-note"></mat-icon>
            <span>{{playlist.name}}</span>
        </button>
    </div>
</div>
