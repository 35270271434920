<ng-container *ngIf="pagination$ | async as pagination">
    <no-results-message *ngIf="!pagination.data.length" svgIcon="mic">
        <span primary-text trans>No artists yet</span>
        <span secondary-text><ng-content></ng-content></span>
    </no-results-message>
    <media-grid *ngIf="pagination.data.length">
        <artist-item [artist]="artist" *ngFor="let artist of pagination.data"></artist-item>
    </media-grid>
</ng-container>
<loading-indicator [isVisible]="loading$ | async" class="inline"></loading-indicator>
