<ng-container *ngIf="artistPage.artist$ | async as artist">
    <media-grid *ngIf="artist.similar.length">
        <artist-item [artist]="artist" *ngFor="let artist of artistPage.allSimilar$ | async"></artist-item>
    </media-grid>

    <no-results-message *ngIf="!artist.similar.length" svgIcon="mic">
            <span primary-text>
                <span trans>Could not find any similar artists for </span>
                <span>{{artist.name}}</span>
            </span>
    </no-results-message>
</ng-container>
